import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
`;
export const Collection = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings.spacing_sm};
`;

export const ItemText = styled.div`
  font-family: "Montserrat Medium";
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blackGreen};
`;
