import styled from "styled-components";
import PButton from "../PButton";

export const Container = styled.div.attrs((props) => {
  role: "header";
})`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 95%;
  max-width: ${({ theme }) => theme.layout.content.maxWidth}px;
  display: flex;
  padding: 0 ${({ theme }) => theme.spacings.spacing_big};
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 ${({ theme }) => theme.spacings.spacing_xxl_m};
  }
`;

export const ContentWrap = styled.div.attrs((props) => ({
  id: "logo1",
}))`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const LogoSection = styled.div`
  margin-left: -6px;
`;

export const CallUsBtnWrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomPButton = styled(PButton)`
  width: 147px;
  @media only screen and (max-width: 768px) {
    width: 109px;
  }
`;
