import styled from "styled-components";
import { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 5px;
`;

const hoveredStyle = css`
  font-family: "Montserrat SemiBold";
  text-decoration-line: underline;
  text-decoration-color: ${({ theme }) => theme.colors.blackGreen};
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
`;

export const TextRow = styled.span`
  cursor: pointer;
  ${({ theme }) => theme.components.menuItem.text};
  font-size: 12px;
  ${({ type }) => type === "category" && `font-family: Montserrat`};
  ${({ type }) => type === "category" && `font-weight: Bold`};
  ${({ type }) => type !== "category" && `text-decoration-line: underline`};
  ${({ type }) => type !== "category" && `padding-left: 6px`};

  &:hover {
    ${({ type }) => type === "child" && hoveredStyle};
  }
`;
