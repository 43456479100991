import React from "react";
import PropTypes from "prop-types";
import { Container, MenuText, MenuIconWrap } from "./styled";

const ChevronImage = (props) => {
  return (
    <span className={props.className}>
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 1.5L6 6L10.5 1.5"
          stroke="#1F1F1F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

const MenuItem = ({
  menuType,
  hovered,
  children,
  menuIcon,
  handleHovered,
  hoverIndex,
  isParentItem,
  financing,
  hoveredIndex
}) => {

  const onKeyUp = (e) => {
    if (e.charCode === 13) {
      handleHovered(true, hoverIndex);
    }
  }
  const onLeave = (e) => {
    handleHovered(false, hoverIndex);
  }

  return (
    <Container
      onMouseEnter={() => {
        handleHovered(true, hoverIndex);
      }}
      onMouseLeave={() => {
        handleHovered(false, hoverIndex);
      }}
    >
      {isParentItem
        ?
        <MenuText
          onKeyPress={onKeyUp}
          tabIndex={0}
          hovered={hovered}
          aria-label={`${children}, dropdown menu`}
        >{children}</MenuText>
        : <MenuText financing={financing} hovered={hovered}>{children}</MenuText>}

      {menuIcon && <MenuIconWrap hovered={hovered}>
        <ChevronImage
          className={`chevron-down 
           ${hovered && (hoveredIndex === hoverIndex)
              ? "open" : ""}`}
        />
      </MenuIconWrap>}
    </Container>
  );
};

MenuItem.propTypes = {
  menuType: PropTypes.string,
  hovered: PropTypes.bool,
  menuIcon: PropTypes.bool,
  children: PropTypes.node,
  setHovered: PropTypes.func,
  dropdown: PropTypes.bool,
};

export default MenuItem;
