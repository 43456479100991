import React, { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { LogoMobile } from "../Logos";
import NavMenu from "./NavMenu";
import {
  Container,
  Header,
  HamburgerBtn,
  PhoneIcon,
  NavContainer,
} from "./styled";
import { desktopMenus } from "../Menu/_nav";
import { eventClickPhoneNumber } from "../../services/analytics/ga4CustomEvents";
import IconTimesClose from "../../public/icons/icon_times-close.svg";
import IconBarSolid from "../../public/icons/icon_bar-solid.svg";
import { useRouter } from "next/router";

const HeaderMobile = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { query, isReady } = useRouter();

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isReady) {
      setIsExpanded(false);
    }
  }, [query]);

  const handleClickCallUs = () => {
    const phoneNumber = "18015727712";
    window.open(`tel:${phoneNumber}`);

    eventClickPhoneNumber(
      "18015727712",
      "Clicks on phone numbers in the mobile header",
    );
  };

  return (
    <Container>
      <Header>
        <HamburgerBtn
          tabIndex="0"
          aria-label="open menu"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setIsExpanded(!isExpanded)
            }
          }}
          onClick={handleClick}
        >
          {isExpanded && (
            <Image className="img" tabIndex="-1" aria-hidden="true" src={IconTimesClose} alt="" />
          )}
          {!isExpanded &&
            (
              <Image className="img" tabIndex="-1" aria-hidden="true" src={IconBarSolid} alt="" />
            )}
        </HamburgerBtn>
        <Link href={"/"}>
          <a role="button">
            <LogoMobile />
          </a>
        </Link>
        <PhoneIcon onClick={handleClickCallUs}>
          {!isExpanded && (
            <Image
              src="/icons/phone_black.png"
              alt="Classy Closets Logo Mobile"
              layout="fill"
              objectFit="contain"
              loading="lazy"
              quality={70}
            />
          )}
        </PhoneIcon>
      </Header>
      <NavMenu setIsExpanded={setIsExpanded} expanded={isExpanded ? "yes" : "no"} menuLists={desktopMenus} />
    </Container>
  );
};

export default HeaderMobile;
