import React, { useState } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { Container } from "./styled";

function NavItem({ name, href, pleft, pright, ptop, pbottom, }) {
  return (
    <Link href={href || "/"} >
      <a>
        <Container
          pleft={pleft}
          pright={pright}
          ptop={ptop}
          pbottom={pbottom}
        >
          {name}
        </Container>
      </a>
    </Link>
  );
}

NavItem.propTypes = {
  name: PropTypes.string,
  imgSrc: PropTypes.string,
  hoverImgSrc: PropTypes.string,
  href: PropTypes.string,
  pleft: PropTypes.string,
  pright: PropTypes.string,
  ptop: PropTypes.string,
  pbottom: PropTypes.string,
};

export default NavItem;
