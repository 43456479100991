import React from "react";
import Link from "next/link";
import MenuItem from "../MenuItem";
import { Container, MenuWrap, NavFooter, TextElm } from "./styled";

const ChevronImage = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.5L6 6L10.5 1.5"
        stroke="#1F1F1F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const NavMenu = ({ expanded, menuLists, setIsExpanded }) => {
  return (
    <Container expanded={expanded}>
      <MenuWrap>
        {menuLists.map((item, index) =>
          item.type !== "parent" ? (
            <Link key={index} href={item.href || "/"}>
              <a>
                <MenuItem item={item} key={index}>
                  {item.title}
                </MenuItem>
              </a>
            </Link>
          ) : (
            <MenuItem
              key={index}
              item={item}
              menuIcon={<ChevronImage />}
              setIsExpanded={setIsExpanded}
              expanded={expanded}
            >
              {item.title}
            </MenuItem>
          ),
        )}
      </MenuWrap>
      <NavFooter>
        <TextElm>
          <Link href={"/franchise-opportunities"}>
            <a className="link">
              Franchise Opportunities
            </a>
          </Link>

          <Link href={"/careers"}>
            <a className="link">
              | Careers
            </a>
          </Link>

        </TextElm>
        <TextElm>8015727712</TextElm>
      </NavFooter>
    </Container>
  );
};

export default NavMenu;
