import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import Header from "../Header";
import HeaderMobile from "../HeaderMobile";
import Footer from "../Footer";
import { IsDesktop, IsMobile } from "../ResponsiveContainers";

import InfoBanner from "../InfoBanner";
import {
  LayoutContainer,
  HeaderContainer,
  MainContainer,
  ContainerWithMax,
  FooterContainer,
  HeaderContainerMobile,
} from "./styled";
import { useHeaderContext } from "../../context/HeaderProvider";
import MainContainerContext from "../../context/MainContainer";
import Head from "next/head";

const AppLayout = ({ children, type, footerBG }) => {
  const { isVisible } = useHeaderContext();
  const wrapperRef = useRef();

  return (
    <>

      <LayoutContainer className="ant-layout">
        <Head>
          <script type="application/ld+json" >

            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Classy Closets",
              "url": "https://closetsutah.com",
              "logo": "https://closetsutah.com/images/logo/Classy-Closets-Email-Logo.png",
              "description": "We design, manufacture and install Custom Closets, cabinetry and home offices",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "1251 S Nelson Dr",
                "addressLocality": "Chandler",
                "addressRegion": "AZ",
                "postalCode": "85226",
                "addressCountry": "United States"
              }
            })}
          </script>
        </Head>
        <InfoBanner role="alert" type="dark" />
        <MainContainerContext.Provider
          value={{ getContainer: () => wrapperRef.current || window }}
        >
          <HeaderContainer isvisible={isVisible ? "yes" : "no"}>
            <Header />
          </HeaderContainer>
          <IsMobile>
            <HeaderContainerMobile>
              <HeaderMobile />
            </HeaderContainerMobile>
          </IsMobile>
          <MainContainer>
            <ContainerWithMax ref={wrapperRef}>{children}</ContainerWithMax>
          </MainContainer>
          <FooterContainer>
            <Footer footerBG={footerBG} />
          </FooterContainer>
        </MainContainerContext.Provider>
      </LayoutContainer>

    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["noHeader", "noFooter"]),
  footerBG: PropTypes.string,
};

export default AppLayout;
