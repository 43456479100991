import React from "react";
import SummaryTitle from "../SummaryTitle";
import NavItem from "../NavItem";
import { Container, NavMenuWrap } from "./styled";
import navItems from "./_footerNavs";

function FooterNav() {
  return (
    <Container>
      <SummaryTitle>Company</SummaryTitle>
      <NavMenuWrap>
        {navItems.map((item, key) => (
          <NavItem key={key} name={item.name} href={item.href} />
        ))}
      </NavMenuWrap>
    </Container>
  );
}

export default FooterNav;
