import React from "react";
import SocialIconLink from "../../SocialIconLink";
import { Container } from "./styled";
import socialLinks from "./_socialLinks";

const SocialLinksSection = () => {
  return (
    <Container>
      {socialLinks &&
        socialLinks.map((item, key) => (
          <SocialIconLink
            key={key}
            imgSrc={item.imgSrc}
            hoverImgSrc={item.hoverImgSrc}
            href={item.href}
            pright="10px"
            label={item.label}
          />
        ))}
    </Container>
  );
};

export default SocialLinksSection;
