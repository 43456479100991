import styled from "styled-components";

export const bannerStyle = {
  LIGHT: "light",
  DARK: "dark",
};

export const InfoButton = styled.button`
  background-color: rgba(255, 255, 255, 1);
  /* border-color: rgba(255, 255, 255, 1); */
  border: 1px solid white;
  color: #762738;
  font-family: "Roboto", sans-serif;
  padding: .1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 1em;
  white-space: nowrap;

  @media (max-width: 500px) {
    margin: 0;
    height: 10rem;
  }

  cursor: pointer;
  .text {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
  
  @media (max-width: 569px) {
    font-size: .9rem;
  }
  }
  .image-container {
    padding-right: 10px;
    padding-top: 2px;
  }
`;

export const Container = styled.div.attrs((props) => ({
  role: "banner",
}))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: ${({ theme, type }) =>
    type === bannerStyle.LIGHT
      ? theme.colors.lightGray2
      : theme.colors.darkGray2};

  @media only screen and (max-width: 800px) {
    /* flex-direction: column; */
    padding: 8px;
  }
  @media only screen and (max-width: 500px) {
   max-height: 65px;
   flex-direction: column;
  }
`;
export const InfoText = styled.span.attrs((props) => ({
}))`
  font-family: "Montserrat Medium";
  font-size: 14px;
  color: ${({ theme, type }) =>
    type === bannerStyle.LIGHT ? theme.colors.blackGreen : theme.colors.white};
  margin-right: 20px;

  @media only screen and (max-width: 800px) {
    font-size: 14px;
    margin-right: 0px;
    text-align: center;
  }
  @media only screen and (max-width: 414px) {
    max-width: 320px;
    font-size: 15px;
  }
`;

export const PhoneNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PhoneNumberText = styled.span.attrs((props) => ({
  // role: "banner",
  // "aria-label": "phonenumber in header",
  // "aria-labelledby": "phonenumber1",
}))`
  font-family: "Montserrat Medium";
  font-size: 16px;
  color: ${({ theme, type }) =>
    type === bannerStyle.LIGHT ? theme.colors.blackGreen : theme.colors.white};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

export const PhoneIcon = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  margin-right: 11px;
  color: ${({ theme, type }) =>
    type === bannerStyle.LIGHT ? theme.colors.blackGreen : theme.colors.white};

  /* & img {
    width: 16px;
    height: 16px;
  } */

  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
