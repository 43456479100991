import styled from "styled-components";

export const Container = styled.div`
  height: 260px;
  width: 100%;
  position: absolute;
  max-width: ${({ theme }) => theme.layout.content.maxWidth}px;
  margin-left: -${({ theme }) => theme.layout.content.paddingHorizontal.desktop}px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow1};
  transform: translateY(0);
  transition: all 0.5s ease;
  ${({ containerWidth }) => containerWidth && `width: ${containerWidth}px`};
  
  @media only screen and (max-width: 768px) {
    margin-left: -${({ theme }) => theme.layout.content.paddingHorizontal.ipad}px;
  }
`;

export const ShadowDiv = styled.div`
  width: 100%;
  height: 2px;
  background-color: #9892921a;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow1};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: ${({ theme }) => theme.spacings.spacing_xl};
  width: 80%;
  height: 100%;
  margin: 0 auto;
`;

export const SubMenuSection = styled.div`
  width: 100%;
`;

export const MenuGroupWrap = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
