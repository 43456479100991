import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  @media screen and (max-width: 768px) and (min-width: 560px) {
  flex: 2;
}
`;

export const SummaryWrap = styled.div`
  max-width: 340px;
`;

export const SummaryTitle = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings.spacing_xs};
  font-family: "Montserrat SemiBold";
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blackGreen};
`;

export const SummaryContent = styled.div`
  font-family: "Montserrat";
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blackGreen};
  padding-right: 30px;
`;

export const LogoWrap = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    margin-left: -10px;
    display: block;
  }
`;
