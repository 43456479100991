import styled from "styled-components";

export const Container = styled.div`
  font-family: "Montserrat Medium";
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blackGreen};
  padding-bottom: ${({ theme }) => theme.spacings.spacing_xxss};

  ${({ pleft }) => pleft && `padding-left: ${pleft}`};
  ${({ pright }) => pright && `padding-right: ${pright}`};
  ${({ ptop }) => ptop && `padding-top: ${ptop}`};
  ${({ pbottom }) => pbottom && `padding-bottom: ${pbottom}`};

  &:hover {
    font-family: "Montserrat SemiBold";
    color: ${({ theme }) => theme.colors.prune};
  }
`;
