import Image from "next/image";
import { ContainerMobile } from "./styled";

function LogoMobile() {
  return (
    <ContainerMobile>
      <Image
        src="/images/logo/ClassyClosets-logo.png"
        alt="Classy Closets Logo Mobile"
        layout="fill"
        objectFit="contain"
        width={910}
        height={150}
        loading="lazy"
        quality={100}
      />
    </ContainerMobile>
  );
}

export default LogoMobile;
