import Image from "next/image";
import { Container } from "./styled";

function Logo() {
  return (
    <Container>
      <Image
        src="/images/logo/ClassyClosets-logo.png"
        alt="Classy Closets Logo"
        layout="fill"
        objectFit="cover"
        loading="lazy"
        quality={70}
      />
    </Container>
  );
}

export default Logo;
