import styled from "styled-components";

export const Container = styled.div`
  padding-left: ${({ theme }) => theme.spacings.spacing_xss};
  padding-bottom: ${({ theme }) => theme.spacings.spacing_l_m};

  @media only screen and (max-width: 560px) {
    padding-left: 0px;
  }
`;

export const TextRow = styled.div`
  font-family: "Open Sans";
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};

  &:hover {
    ${({ theme, hovered }) => hovered && `color: ${theme.colors.prune}`};
    ${({ theme, hovered }) => hovered && `text-decoration: underline`};
  }
`;

export const Wrap = styled.div`
  display: flex;
`;
