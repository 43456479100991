import React from "react";
import PropTypes from "prop-types";
import SubMenuItem from "./SubMenuItem";
import { Container } from "./styled";

const SubNavMenu = ({ items, expanded }) => {
  return (
    <Container expanded={expanded}>
      {items.map((item, key) => (
        <SubMenuItem key={key} item={item} />
      ))}
    </Container>
  );
};

SubNavMenu.propTypes = {
  items: PropTypes.array,
  expanded: PropTypes.bool,
};

export default SubNavMenu;
