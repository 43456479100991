import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow1};
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  z-index: 99;
  padding: 0 ${({ theme }) => theme.layout.content.paddingHorizontal.mobile}px;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.shadow1};
`;

export const HamburgerBtn = styled.div`
  width: 24px;
  font-size: 24px;
  color: black;
  cursor: pointer;

  .img {
    width: 24px;
    height: 24px;
  }
`;

export const PhoneIcon = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
`;

export const NavContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transform: ${({ expanded }) =>
    expanded === "yes" ? "translateY(0)" : "translateY(-100%)"};
  /* transition: transform 0.5s ease-in-out; */
  z-index: 90;
  margin-top: ${({ theme }) => theme.layout.header.heightMobile}px;
`;
