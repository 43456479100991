import React from "react";
import Link from "next/link";
import { Container, TextRow, Wrap } from "./styled";

function PolicyAndTerms() {
  return (
    <Container>
      <TextRow>© {(new Date().getFullYear())} Classy Closets, All Rights Reserved</TextRow>
      <Wrap>
        <Link href="/privacy-policy">
          <a>
            <TextRow hovered={true}>Privacy Policy</TextRow>
          </a>
        </Link>
        <TextRow> | </TextRow>
        <Link href="/">
          <a>
            <TextRow hovered={true}>Terms</TextRow>
          </a>
        </Link>
      </Wrap>
    </Container>
  );
}

export default PolicyAndTerms;
