import React, { useState } from "react";
import Link from "next/link";
import MenuItem from "./MenuItem";
import SubMenu from "./SubMenu";
import { Container, MenuWrap } from "./styled";
import { desktopMenus } from "./_nav";

const Menu = () => {
  const [hovered, setHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState();

  const handleHovered = (hover, index) => {
    setHovered(hover);
    setHoveredIndex(index);
  };

  return (
    <Container>
      <MenuWrap>
        {desktopMenus.map((item, index) => (
          <div key={item.title}>
            {item.type === "parent" ? (
              <>
                <MenuItem
                  hoverIndex={index}
                  hovered={hovered}
                  handleHovered={handleHovered}
                  hoveredIndex={hoveredIndex}
                  menuIcon={item.type === "parent" ? true : false}
                  isParentItem={true}
                >
                  {item.title}
                </MenuItem>
                {hovered &&
                  hoveredIndex !== undefined &&
                  (desktopMenus[hoveredIndex].type === "parent" ? (
                    <SubMenu
                      childItems={desktopMenus[hoveredIndex].children}
                      hoverIndex={hoveredIndex}
                      handleHovered={handleHovered}
                    />
                  ) : (
                    ""
                  ))}
              </>
            ) : (
              <Link href={item.href}>
                <a>
                  <MenuItem
                    hoverIndex={index}
                    hovered={hovered}
                    handleHovered={handleHovered}
                    financing={item.financing}
                  >
                    {item.title}
                  </MenuItem>
                </a>
              </Link>
            )}
          </div>
        ))}
      </MenuWrap>

      {hovered &&
        hoveredIndex !== undefined &&
        (desktopMenus[hoveredIndex].type === "parent" ? (
          <SubMenu
            childItems={desktopMenus[hoveredIndex].children}
            hoverIndex={hoveredIndex}
            handleHovered={handleHovered}
          />
        ) : (
          ""
        ))}
    </Container>
  );
};

export default Menu;
