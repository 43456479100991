import React from "react";
import SocialLinksSection from "../SocialLinksSection";
import SummaryTitle from "../SummaryTitle";
import PolicyAndTerms from "../PolicyAndTerms";
import LogoSecondary from "../../Logos/LogoSecondary";
import { Container, SummaryWrap, SummaryContent, LogoWrap } from "./styled";

const SummaryAndSocial = () => {
  return (
    <Container>
      <LogoWrap>
        <LogoSecondary />
      </LogoWrap>
      <SummaryWrap>
        <SummaryTitle>Experience the difference</SummaryTitle>
        <SummaryContent>
        Classy Closets gives you custom closet designs made by our expert designers to fit what you want and need. Your special design will look great and work well. No need to pick between looks and usefulness - you can have both with us! Choose from many styles, materials, finishes, hardware, and extras to make the perfect closet, kitchen counter, pantry, garage setup, or storage just for you.
        </SummaryContent>
      </SummaryWrap>
      <SocialLinksSection />
      <PolicyAndTerms />
    </Container>
  );
};

export default SummaryAndSocial;
