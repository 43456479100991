import React from "react";
import { NotMobileContainer } from "./styled";

const IsNotMobile = ({ children, height, width }) => {
  return (
    <NotMobileContainer height={height} width={width}>
      {children}
    </NotMobileContainer>
  );
};

export default IsNotMobile;
