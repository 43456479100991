import Image from "next/image";
import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  InfoText,
  bannerStyle,
  InfoButton,
} from "./styled";
import { eventClickPhoneNumber } from "../../services/analytics/ga4CustomEvents";
import Link from "next/link";

const InfoBanner = ({ type }) => {
  const handleClickCallUs = () => {
    const phoneNumber = "8015727712";
    window.open(`tel:${phoneNumber}`);

    eventClickPhoneNumber(
      "8015727712",
      "Clicks on phone numbers in the Info Banner",
    );
  };


  return (
    <Container type={type}>
      <Link passHref href={"/get-in-touch"}>
        <InfoText as="a" type={type}>Schedule your FREE consultation.</InfoText>
      </Link>

      <InfoButton
        as="a"
        href="tel:801-572-7712"
        className="get-started-info-button"
      >

        <span className="image-container">
          <Image
            src="/icons/button-phone-icon.png"
            width={18}
            height={18}
            loading="lazy"
            alt="phone icon"
          /> </span>
        <span className="text" >801-572-7712</span>
      </InfoButton>
      {/* <PhoneNumber>
        <PhoneIcon onClick={handleClickCallUs} type={type}>
          <Image src={IconPhoneAlt} 
          alt="Phone icon" 
          loading="lazy"
          width={16}
          height={16}
          />
        </PhoneIcon>
        <PhoneNumberText onClick={handleClickCallUs} type={type}>
          1-866-580-2280
        </PhoneNumberText>
      </PhoneNumber> */}
    </Container>
  );
};

InfoBanner.propTypes = {
  type: PropTypes.oneOf([bannerStyle.LIGHT, bannerStyle.DARK]),
};

InfoBanner.defaultProps = {
  type: bannerStyle.LIGHT,
};

export default InfoBanner;
