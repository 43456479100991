import React from "react";
import Link from "next/link";
import { Container, TextRow } from "./styled";

function SubMenuItem({ item: { type, title, href } }) {
  return (
    <Container>
      <Link href={href || "/"}>
        <a role="menuitem">
          <TextRow type={type}>{title}</TextRow>
        </a>
      </Link>
    </Container>
  );
}

export default SubMenuItem;
