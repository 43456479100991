const arr = [
  {
    name: "Why Choose Classy Closets",
    href: "/why-choose-classy-closets"
  },
  {
    name: "Showroom",
    href: "/locations/salt-lake-city"
  },

  {
    name: "Careers",
    href: "/careers"
  },
  {
    name: "FAQ",
    href: "/frequently-asked-questions"
  },
  {
    name: "Accessibility Statement",
    href: "/accessibility-statement"
  },
]

export default arr;
