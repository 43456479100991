import React from "react";
import { DesktopContainer } from "./styled";

const IsDesktop = ({ children, height, width }) => {
  return (
    <DesktopContainer height={height} width={width}>
      {children}
    </DesktopContainer>
  );
};

export default IsDesktop;
