import React from "react";

import NavItem from "../NavItem";
import SummaryTitle from "../SummaryTitle";
import { Container, Collection, ItemText } from "./styled";
import {
  eventClickPhoneNumber,
  eventClickEmail,
} from "../../../services/analytics/ga4CustomEvents";

function ContactUs() {
  return (
    <Container>
      <SummaryTitle>Contact Us</SummaryTitle>
      <Collection>
        <ItemText>Corporate Office</ItemText>
        <ItemText>
          <NavItem
            href={"http://maps.google.com/?q=5585 West Wells Park Road West Jordan, UT 84047"}
            name={"5585 West Wells Park Road West Jordan, UT 84081 (external link to maps)"}
          />
        </ItemText>
      </Collection>
      <Collection>
        <ItemText
          onClick={() =>
            eventClickPhoneNumber(
              "18015727712",
              "Clicks on phone numbers in the Footer",
            )
          }
        >
          <NavItem name="801-572-7712" href="tel:18015727712" />
        </ItemText>
        <ItemText>Monday-Friday: 10am-5pm</ItemText>
        <ItemText>Saturday: 10am-3pm</ItemText>
        <ItemText>Sunday: Closed</ItemText>
      </Collection>
      <Collection>
        <ItemText
          onClick={() => eventClickEmail("customercare@classyclosetsut.com")}
        >
          <NavItem
            href={"mailto:customercare@classyclosetsut.com"}
            name={"customercare@classyclosetsut.com"}
          />
        </ItemText>
      </Collection>
    </Container>
  );
}

export default ContactUs;
