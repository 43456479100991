import React from "react";
import Link from "next/link";
import SocialLinksSection from "../SocialLinksSection";
import PolicyAndTerms from "../PolicyAndTerms";
// import NavMenu from "../../HeaderMobile/NavMenu";
import MenuItem from "./MenuItem";
import { Container, FooterMenuWrap, MenuWrap } from "./styled";
import { mobileFooterMenus } from "../../Menu/_nav";

const MobileFooter = () => {
  return (
    <Container>
      <FooterMenuWrap>
        <MenuWrap>
          {mobileFooterMenus.map((item, index) =>
            item.type !== "parent" ? (
              <Link key={index} href={item.href || "/"}>
                <a>
                  <MenuItem item={item} key={index}>
                    {item.title}
                  </MenuItem>
                </a>
              </Link>
            ) : (
              <MenuItem
                key={index}
                item={item}
                menuIcon={<i className="fas fa-chevron-down" />}
              >
                {item.title}
              </MenuItem>
            ),
          )}
        </MenuWrap>
      </FooterMenuWrap>
      <SocialLinksSection />
      <PolicyAndTerms />
    </Container>
  );
};

export default MobileFooter;
