import React from "react";
import PropTypes from "prop-types";

import LogoSecondary from "../Logos/LogoSecondary";
import SummaryAndSocial from "./SummaryAndSocial";
import FooterNav from "./FooterNav";
import ContactUs from "./ContactUs";
import MobileFooter from "./MobileFooter";
import {
  Container,
  FooterContentWrap,
  Wrap,
  LogoWrap,
  DesktopFooter,
  MobileFooterWrap,
} from "./styled";

const Footer = ({ footerBG }) => {
  return (
    <Container bgcolor={footerBG}>
      <DesktopFooter>
        <LogoWrap>
          <LogoSecondary />
        </LogoWrap>
        <FooterContentWrap>
          <SummaryAndSocial />
          <Wrap>
            <FooterNav />
            <ContactUs />
          </Wrap>
        </FooterContentWrap>
      </DesktopFooter>
      <MobileFooterWrap>
        <MobileFooter />
      </MobileFooterWrap>
    </Container>
  );
};

Footer.propTypes = {
  footerBG: PropTypes.string,
};

export default Footer;
