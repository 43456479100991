import styled from "styled-components";
import { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.darkGray};
`;

const selectedStyle = css`
  /* color: ${({ theme }) => theme.colors.prune}; */
`;

export const ItemWrap = styled.div`
  display: flex;
`;

export const MenuText = styled.div`
  ${({ theme }) => theme.components.menuItem.text};
  padding-left: 6px;
  font-size: 12px;
  ${({ selected }) => selected && selectedStyle};
`;

export const MenuIconWrap = styled.div`
  ${({ theme }) => theme.components.menuItem.text};
  font-size: 13px;
  text-align: center;
  align-items: center;
  display: flex;
  padding-top: 4px;
  padding-left: 4px;
  ${({ selected }) => selected && selectedStyle};
`;
