import styled, { css } from "styled-components";

const expandedStyle = css`
  overflow-y: hidden;
  padding-top: 8px;
  max-height: 500px;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
`;

const collapsedStyle = css`
  overflow: hidden;
  max-height: 0;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
`;

export const Container = styled.div`
  padding-left: 12px;
  ${collapsedStyle};
  ${({ expanded }) => expanded && expandedStyle};
`;
