import React, { useState } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";
import { Container } from "./styled";

function SocialIconLink({
  imgSrc,
  hoverImgSrc,
  href,
  pleft,
  pright,
  ptop,
  pbottom,
  label
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <Link href={href || "/"}>
      <a aria-label={label}>
        <Container
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          pleft={pleft}
          pright={pright}
          ptop={ptop}
          pbottom={pbottom}
        >
          <Image
            src={!hovered ? imgSrc : hoverImgSrc}
            alt="social logo icon"
            width={36}
            height={36}
            loading="lazy"
            quality={80}
          />
        </Container>
      </a>
    </Link>
  );
}

SocialIconLink.propTypes = {
  imgSrc: PropTypes.string,
  hoverImgSrc: PropTypes.string,
  href: PropTypes.string,
  pleft: PropTypes.string,
  pright: PropTypes.string,
  ptop: PropTypes.string,
  pbottom: PropTypes.string,
};

export default SocialIconLink;
