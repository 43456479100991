import styled from "styled-components";
import { css } from "styled-components";

const financingStyle = css`
border: 1.3px solid #762738;
color: #762738;
padding: 0px 2px;
padding-right: 5px;
`;

export const Container = styled.div`
  display: flex;
  padding-right: ${({ theme }) => theme.spacings.spacing_ml};
  padding-bottom: ${({ theme }) => theme.spacings.spacing_s};
  @media only screen and (max-width: 768px) {
    padding-right: ${({ theme }) => theme.spacings.spacing_ml};
    padding-bottom: ${({ theme }) => theme.spacings.spacing_s};
  }
`;

const hoveredStyle = css`
  text-decoration-line: underline;
  text-decoration-color: ${({ theme }) => theme.colors.prune};
  color: ${({ theme }) => theme.colors.prune};
  text-decoration-thickness: 2px;
  /* text-underline-offset: 4px; */
  /* transition: transform 0.5s ease-in-out; */
  cursor: pointer;
`;

export const MenuText = styled.div`
  ${({ theme }) => theme.components.menuItem.text};
  ${props => props.financing && financingStyle};
  /* ${({ hovered, theme }) => hovered && `color: ${theme.colors.prune}`}; */
  &:hover {
    ${({ hovered }) => hovered && hoveredStyle};
  }
`;

export const MenuIconWrap = styled.div`
  ${({ theme }) => theme.components.menuItem.text};
  font-size: 13px;
  text-align: center;
  align-items: center;
  display: flex;
  padding-top: 4px;
  padding-left: 4px;
   .chevron-down {
    transition: 0.5s cubic-bezier(0.19, 1, 0.19, 1);
    &.open {
      transform: rotate(180deg);
    }
    
  }
  /* ${({ hovered, theme }) => hovered && `color: ${theme.colors.prune}`}; */
`;
